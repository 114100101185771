<template>
  <div class="container-dashboard">

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-facturacion" customClassText="custom-card-text-facturacion"
              title="Envios sin asignar" subtitle="Hoy" :price="assigned_total"
              :loading="loading_shippings_assigned"  :isIcon="true" iconType="shipping_seller" />

          </v-col>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-envios" customClassText="custom-card-text-envios"
              title="Envíos en camino" subtitle="Hoy" :price="on_the_way_total"
              :loading="loading_on_the_way" :isIcon="false"/>
          </v-col>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-envios" customClassText="custom-card-text-envios"
              title="Envíos entregados" subtitle="Hoy" :price="delivered_total"
              :loading="loading_delivered" :isIcon="false" />
          </v-col>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-envios-not-delivered" customClassText="custom-card-text-envios"
              title="Envíos no entregados" subtitle="Hoy" :price="not_delivered_total"
              :loading="loading_not_delivered" :isIcon="false" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardOrders :loading="loading" :orders="list"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { dashboardService } from "@/libs/ws/Seller/dashboardService";
import CardDashboard from "@/components/CardDashboard.vue";
import CardOrders from "@/components/CardOrders.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
import { ordersService } from "@/libs/ws/Seller/ordersService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import OrderState from "@/constants/order_state";

export default {
  name: "Dashboard",
  components: { CardDashboard,CardOrders },
  mixins: [GenericMixin],
  data() {
    return {
      formatUtils: formatUtils,
      ordersService: ordersService,
      page_title: "Dashboard Seller",
      assigned_total: 0,
      on_the_way_total: 0,
      delivered_total: 0,
      not_delivered_total: 0,
      loading_not_delivered: false,
      loading_shippings_assigned: false,
      loading_on_the_way: false,
      loading_delivered: false,
      loading_orders: false,
      orders: [],
      params: {
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10),
        state: [ OrderState.STATE_ACEPTED, OrderState.STATE_PENDIENTE_RETIRO, OrderState.STATE_COLECTADA, OrderState.STATE_FINALIZADA] //sacar 2 y 3 
      },
    
    };
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "MONITOREO" }] });

    this.loadList(this.ordersService, this.params);

    this.getCountShippingsAssigned();
    this.getCountShippingsOnTheWay();
    this.getCountDelivered();
    this.getCountNotDelivered();
  },
  methods: {
    getCountShippingsAssigned() {
      this.loading_shippings_assigned = true;
      dashboardService
        .shippings_assigned()
        .then((response) => {
          this.assigned_total = response.result.assigned;
        })
        .catch(e => console.log("error get count sellers", e))
        .finally(() => {
          this.loading_shippings_assigned = false;
        })
    },
    getCountShippingsOnTheWay() {
      this.loading_on_the_way = true;
      dashboardService
        .shippings_on_the_way()
        .then((response) => {
          this.on_the_way_total = response.result.onTheWay;
        })
        .catch(e => console.log("e get count logistics", e))
        .finally(() => {
          this.loading_on_the_way = false
        })
    },
    getCountDelivered() {
      this.loading_delivered = true;
      dashboardService
        .shippings_delivered()
        .then((response) => {
          this.delivered_total = response.result.delivered;
        })
        .catch(e => console.log("e get count independent driver", e))
        .finally(() => {
          this.loading_delivered = false;
        })
    },
    getCountNotDelivered() {
      this.loading_not_delivered = true;
      dashboardService
        .shippings_not_delivered()
        .then((response) => {
          this.not_delivered_total = response.result.notDelivered;
        })
        .catch(e => console.log("e get count payment", e))
        .finally(() => {
          this.loading_not_delivered = false;
        })
    },
  },
};
</script>
