import { wsService } from "../wsService";

const section = "dashboard";

function shippings_assigned(param = { enable: true }) {
  return wsService.make_post_request(section + "/shippings-assigned", param);
}

function shippings_on_the_way(param = {}) {
  return wsService.make_post_request(section + "/shippings-on-the-way", param);
}

function shippings_delivered(param = {}) {
  return wsService.make_post_request(section + "/shippings-delivered", param);
}

function shippings_not_delivered(param = {}) {
  return wsService.make_post_request(section + "/shippings-not-delivered", param);
}

export const dashboardService = {
  section,
  shippings_assigned,
  shippings_on_the_way,
  shippings_delivered,
  shippings_not_delivered
};
