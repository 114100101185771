import Vuex from "vuex";

export default new Vuex.Store({
  state: {
    sidebar: {
      open: true,
      drawer: true,
    },
    config: {
      items_per_page: 10,
    },
    pageTitle: {
      page_title: []
    }
  },
  mutations: {
    setOpenSidebar: function (state, payload) {
      if (payload.open != undefined) state.sidebar.open = payload.open;

      if (payload.drawer != undefined) state.sidebar.drawer = payload.drawer;
    },
    setPageTitle: function (state, payload) {
      state.pageTitle.page_title = payload.pageTitle
    }
  },
  actions: {},
  modules: {},
});
