<template>
  <div class="container-dashboard">

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-facturacion" customClassText="custom-card-text-facturacion"
              title="Cotizaciones pendientes" subtitle="Hoy" :price="pending_total"
              :loading="loading_quoting_pending"  :isIcon="false"  />

          </v-col>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-envios" customClassText="custom-card-text-envios"
              title="Envíos en camino" subtitle="Hoy" :price="on_the_way_total"
              :loading="loading_on_the_way" :isIcon="false"/>
          </v-col>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-envios" customClassText="custom-card-text-envios"
              title="Envíos entregados" subtitle="Hoy" :price="delivered_total"
              :loading="loading_delivered" :isIcon="false" />
          </v-col>
          <v-col cols="3">
            <CardDashboard customClass="custom-card-envios-not-delivered" customClassText="custom-card-text-envios"
              title="Envíos no entregados" subtitle="Hoy" :price="not_delivered_total"
              :loading="loading_not_delivered" :isIcon="false" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardOrdersQuoting :loading="loading" :orders="list"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { dashboardService } from "@/libs/ws/Logistic/dashboardService";

import CardDashboard from "@/components/CardDashboard.vue";
import CardOrdersQuoting from "@/components/CardOrdersQuoting.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
import { ordersQuotingService } from "@/libs/ws/Logistic/ordersQuotingService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import OrderQuotingState from "@/constants/order_quotings_state";

export default {
  name: "Dashboard",
  components: { CardDashboard,CardOrdersQuoting },
  mixins: [GenericMixin],
  data() {
    return {
      formatUtils: formatUtils,
      ordersQuotingService: ordersQuotingService,
      page_title: "Dashboard Logistic",
      pending_total: 0,
      on_the_way_total: 0,
      delivered_total: 0,
      not_delivered_total: 0,
      loading_not_delivered: false,
      loading_quoting_pending: false,
      loading_on_the_way: false,
      loading_delivered: false,
      loading_orders: false,
      orders: [],
      params: {
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10),
        state: [ OrderQuotingState.STATE_ACCEPTED, OrderQuotingState.STATE_CHARGED] 
      },
    
    };
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "MONITOREO" }] });

    this.loadList(this.ordersQuotingService, this.params);

    this.getCountQuotingPending();
    this.getCountShippingsOnTheWay();
    this.getCountDelivered();
    this.getCountNotDelivered();
  },
  methods: {
    getCountQuotingPending() {
      this.loading_quoting_pending = true;
      dashboardService
        .quotings_pending()
        .then((response) => {
          this.pending_total = response.result.pending;
        })
        .catch(e => console.log("error get count sellers", e))
        .finally(() => {
          this.loading_quoting_pending = false;
        })
    },
    getCountShippingsOnTheWay() {
      this.loading_on_the_way = true;
      dashboardService
        .shippings_on_the_way()
        .then((response) => {
          this.on_the_way_total = response.result.onTheWay;
        })
        .catch(e => console.log("e get count logistics", e))
        .finally(() => {
          this.loading_on_the_way = false
        })
    },
    getCountDelivered() {
      this.loading_delivered = true;
      dashboardService
        .shippings_delivered()
        .then((response) => {
          this.delivered_total = response.result.delivered;
        })
        .catch(e => console.log("e get count independent driver", e))
        .finally(() => {
          this.loading_delivered = false;
        })
    },
    getCountNotDelivered() {
      this.loading_not_delivered = true;
      dashboardService
        .shippings_not_delivered()
        .then((response) => {
          this.not_delivered_total = response.result.notDelivered;
        })
        .catch(e => console.log("e get count payment", e))
        .finally(() => {
          this.loading_not_delivered = false;
        })
    },
  },
};
</script>
