<template>
  <div class="skeleton-card-dashboard" v-if="loading">
    <v-skeleton-loader class="skeleton-card-loader" type="card"></v-skeleton-loader>
  </div>
  <v-card v-else :class="customClass" class="custom-card-dashboard">
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <div>
            <v-label :class="customClassText" class="title-card-dashboard mb-0">{{ title }}</v-label>
          </div>
          <div>
            <v-label :class="customClassText" class="subtitle-card-dashboard">{{ subtitle }}</v-label>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex justify-content-end" v-if="isIcon">
          <font-awesome-icon
            v-if="selectedIcon"
            :icon="selectedIcon"
            :class="iconClass"
            class="icon-dashboard mr-3 pt-2"
          />
        </v-col>
        <v-col cols="12" class="text-end pt-0 pb-0">
          <v-label :class="customClassText" class="price-card-dashboard mr-3">{{ price }}</v-label>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    customClassText: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "", // Clave usada para seleccionar el icono y su color
    },
    isIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    selectedIcon() {
      const icons = {
        payment: ['fas', 'dollar-sign'],
        shipping: ['fas', 'paper-plane'],
        shipping_seller: ['fas', 'paper-plane'],
        warning: ['fas', 'exclamation-triangle'],
        success: ['fas', 'check-circle'],
      };
      return icons[this.iconType] || null;
    },
    iconClass() {
      const iconClasses = {
        payment: "icon-payment",
        shipping_seller: "icon-payment",
        shipping: "icon-shipping",
        warning: "icon-warning",
        success: "icon-success",
      };
      return iconClasses[this.iconType] || "";
    }
  }
};
</script>

