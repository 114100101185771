import { wsService } from "../wsService";

const section = "invoicing";

function invoicing_logistic(param = { enable: true }) {
  return wsService.make_post_request(section + "/list-invoicing", param);
}


export const invoicingService = {
  section,
  invoicing_logistic
};
