<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    :type="type"
    :class="computedClasses"
    :rounded="rounded"
    :height="height"
    :variant="variant"
    :color="color"
    @click="$emit('click')"
  >
    <v-icon v-if="hasIcon" :size="iconSize">{{ icon }}</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    classes: String,
    rounded: String,
    height: String,
    icon: String,
    iconSize: String,
    label: String,
    onClick: Function,
    loading: Boolean,
    type: String,
    disabled: Boolean,
    variant: String,
    color: String
  },
  computed: {
    hasIcon() {
      return this.icon && this.icon.length > 0;
    },
    computedClasses() {
      return [
        this.classes,
        { 'opacity-50 bg-gray-400 text-white': this.disabled } // Clases para cuando está deshabilitado
      ];
    },
  },
};
</script>
