<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="1200px"
      transition="dialog-bottom-transition">


      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <!-- START FORM DIALOG -->
            <v-row class="align-center justify-center mb-4">
              <v-col md="11" class="elevation-0 pt-4 pb-4 mt-4" style="background: #f5f5f5">
                <v-stepper v-model="step" :items="steps" hide-actions>
                  <template v-slot:[`item.${1}`]>
                    <QuotationStep1 :loading_quotation="loading_quotation" :quotations="quotations"
                      @handleSelectQuotation="handleSelectQuotation" :selectedQuotation="selectedQuotation"
                      :total_quotations="total_quotations" :items_per_page="items_per_page_quotations"
                      @updatePage="handlePageChangeQuotations" />
                  </template>

                  <template v-slot:[`item.${2}`]>
                    <QuotationStep2 :total_details="total_details" :items_per_page="items_per_page_details"
                      @updatePage="handlePageChangeDetails" :step="step" :quotations_details="quotations_details"
                      :loading_quotation_detail="loading_quotation_detail" :total_price="total_price"
                      :selectedQuotation="selectedQuotation" />
                  </template>
                  <v-card-actions>
                    <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl" height="50"
                    text @click="closeModal()" label="Cerrar" color="error"></Button>
                    <div class="w-100 d-flex justify-content-end pb-3 pe-4" v-if="step === 1">
                     

                      <Button flat :loading="loading_quotation_detail" text @click="next()"
                        :disabled="selectedQuotation === null"
                        class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                        label="Siguiente" v-if="step === 1"></Button>
                    </div>
  
                    <div class="d-flex justify-content-between w-100 ps-2 pe-4" v-if="step == 2">
                      <Button v-if="step == 2" flat class="btn text-capitalize me-1 mb-0 btn-modal" type="button"
                        rounded="xl" height="50" text @click="prev()" label="Atras" color="purple"
                        :disabled="false"></Button>
  
                      <Button flat :loading="loading" text
                        class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                        label="Cotizar" v-if="step === 2" @click="handleQuotationSelected"></Button>
                    </div>

                  </v-card-actions>
                </v-stepper>
              </v-col>
            </v-row>
          </v-card-text>
        
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { ordersService } from "@/libs/ws/Seller/ordersService";
import QuotationStep1 from "./QuotationStep1.vue";
import QuotationStep2 from "./QuotationStep2.vue";


export default {
  mixins: [ValidMixin],
  components: { Button, QuotationStep1, QuotationStep2 },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",
      step: 1,
      item: {
        id: 0,
        code: "",
        creation_date: "",
        state: null

      },
      steps: [
        {
          title: "Cotizaciones",
          content: "First-content",
          value: 1
        },
        {
          title: "Detalle",
          content: "Second-content",
          value: 2
        },
      ],
      loading_quotation: false,
      quotations: [],
      quotations_details: [],
      loadin_quotation_detail: false,
      defaultItem: null,
      items_per_page_quotations: 8,
      current_page_quotations: 1,
      total_quotations: 0,
      total_price: 0,
      selectedQuotation: null,
      items_per_page_details: 4,
      current_page_details: 1,
      total_details: 0,

      loading_quotation_detail: false,
    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));

          const params = {
            order_id: this.item.id,
            from: this.current_page_quotations,
            limit: this.items_per_page_quotations
          }

          this.handleQuotation(params)

        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    prev() {
      this.step--;
    },

    handleQuotationDetails(params) {
      ordersService
        .quotationDetail(params)
        .then((response) => {
          if (response.status) {
            this.quotations_details = response.result.list;
            this.total_details = response.result.total;

            this.loading_quotation_detail = false;
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
          this.$emit("updateDialog", false);
        })
        .finally(() => {
          this.loading_quotation_detail = false;
        });
    },
    async next() {
      if (this.selectedQuotation == null) {
        return;
      }

      this.loading_quotation_detail = true;

      const params = {
        order_id: this.selectedQuotation.order_id,
        logistic_id: this.selectedQuotation.logistic != null ? this.selectedQuotation.logistic.id : null,
        idriver_id: this.selectedQuotation.idriver != null ? this.selectedQuotation.idriver.id : null,
        from: this.current_page_details,
        limit: this.items_per_page_details
      }

      this.total_price = this.selectedQuotation.price;
      this.handleQuotationDetails(params);
      this.step++;
    },
    closeModal() {
      this.$emit('updateDialog', false);
      this.selectedQuotation = null;
      this.step = 1;
      this.current_page_quotations = 1;
      this.current_page_details = 1;
    },
    handleSelectQuotation(quotation) {
      this.selectedQuotation = quotation;
    },
    handleQuotation(params) {
      this.loading_quotation = true;


      ordersService
        .quotation(params)
        .then((response) => {
          if (response.status) {

            this.quotations = response.result.list;
            this.cant_pages_quotations = Math.ceil(response.result.total / this.$store.state.config.items_per_page_quotations);
            this.total_quotations = response.result.total

          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
          this.$emit("updateDialog", false);
        })
        .finally(() => {
          this.order_id = null;
          this.loading_quotation = false;
        });

    },
    handlePageChangeQuotations(page) {
      this.current_page_quotations = page;
      const params = {
        order_id: this.item.id,
        from: this.current_page_quotations,
        limit: this.items_per_page_quotations,
      };
      this.handleQuotation(params);
    },
    handlePageChangeDetails(page) {
      this.current_page_details = page;

      const params = {
        order_id: this.selectedQuotation.order_id,
        logistic_id: this.selectedQuotation.logistic != null ? this.selectedQuotation.logistic.id : null,
        idriver_id: this.selectedQuotation.idriver != null ? this.selectedQuotation.idriver.id : null,
        from: this.current_page_details,
        limit: this.items_per_page_details
      }

      this.handleQuotationDetails(params);
    },
    handleQuotationSelected() {
      this.loading = true;
      const params = {
        order_id: this.selectedQuotation.order_id,
        logistic_id: this.selectedQuotation.logistic != null ? this.selectedQuotation.logistic.id : null,
        idriver_id: this.selectedQuotation.idriver != null ? this.selectedQuotation.idriver.id : null,
        price_agreed: this.selectedQuotation.price
      }

      ordersService
        .quotationSelected(params)
        .then((response) => {
          if (response.status) {
            this.toast.success("La " + this.modal_title + " se cotizó correctamente.");
            this.closeModal();
            this.$emit("loadList");
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>
