import { wsService } from "../wsService";

const section = "dashboard";

function quotings_pending(param = { enable: true }) {
  return wsService.make_post_request(section + "/quotings-pending", param);
}

function shippings_on_the_way(param = {}) {
  return wsService.make_post_request(section + "/shippings-logistic-on-the-way", param);
}

function shippings_delivered(param = {}) {
  return wsService.make_post_request(section + "//shippings-logistic-delivered", param);
}

function shippings_not_delivered(param = {}) {
  return wsService.make_post_request(section + "//shippings-logistic-not-delivered", param);
}

export const dashboardService = {
  section,
  quotings_pending,
  shippings_on_the_way,
  shippings_delivered,
  shippings_not_delivered
};
