<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6"></v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Filters  @loadList="loadList" :service="service"/>

        <Table :list="list" :header="header"
          :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy" :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">#{{ item.order_quoting.seller_order.id }}</td>
            <td class="table-cell">{{ item.order_quoting.seller_order.shippings.length > 0 ? item.order_quoting.seller_order.shippings.length : "-" }}</td>
            <td class="table-cell">{{ formatUtils.formatPrice(item.net_received_amount) }}</td>
            <td class="table-cell">{{ formatUtils.formatPrice(item.order_quoting.fee_yegoo) }}</td>
            <td class="table-cell">{{ formatUtils.formatPrice(parseFloat(item.order_quoting.price_agreed).toFixed(2) - parseFloat(item.order_quoting.fee_yegoo).toFixed(2))}}</td>
          </template>
        </Table>

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { invoicingService } from "@/libs/ws/Logistic/invoicingService";
import { formatUtils } from "@/libs/utils/formatUtils";
import ConstantUserType from "@/constants/user_type";
import Filters from './Filters.vue';

export default {
  name: "Facturación",
  components: { Filters, Table },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: invoicingService,
      formatUtils: formatUtils,
      loading: false,
      userType: "",
      ConstantUserType: ConstantUserType,
      header: [
        { name: "N° Orden", value: "seller_order.id", align: "left" },
        { name: "Cant. envios", value: "seller_order.shippings", align: "left" },
        { name: "Total orden", value: "price_agreed", align: "left" },
        { name: "Comision", value: "fee_yegoo", align: "left" },
        { name: "Total a cobrar", value: "", align: "left" },
       
      ],
   
    };
  },
  computed: {
   
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "Facturacion" }] });
    this.loadList(this.service);
  },
  methods: {

    loadList(service, params) {
      this.loading = true;

      const param = {
        enable: true,
        from: this.current_page,
        limit: this.$store.state.config.items_per_page,
        ...params
      };

      this.open_filter = false;
      service
        .invoicing_logistic(param)
        .then((response) => {
          if (param.from == 0) {
            this.current_page = 1;
          }
          this.list = response.result.list;
          this.cant_pages = Math.ceil(response.result.total / this.$store.state.config.items_per_page);
          this.total = response.result.total;
          this.originalList = [...response.result.list];
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },

    
  }
};
</script>
