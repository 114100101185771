<template>
  <div class="skeleton-card-dashboard" v-if="loading">
    <v-skeleton-loader v-for="n in 3" :key="n" class="skeleton-card-loader mb-4"
    type="card"></v-skeleton-loader>
  </div>
  <v-card v-else class="custom-card-order mb-4" v-for="(order) in orders" :key="order.id" @click="viewItemPage(order)">
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="3">
              <v-label class="title-card-order mb-0">Orden #{{ order.id }}</v-label>
            </v-col>
            <v-col cols="9">
              <v-row>
                <v-col cols="6">
                  <div>

                    <div>
                      <v-label class="subtitle-card-order mb-0">Cliente</v-label>
                    </div>
                    <v-label class="title-card-order mb-0">{{order.seller_order.seller_user.name}}</v-label>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div>
                    <div>
                      <v-label class="subtitle-card-order mb-0">Envios</v-label>
                    </div>
                    <v-label class="title-card-order mb-0">{{ order.seller_order.shippings.length }}</v-label>

                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <div class="shippings-container">
            <div class="shipping-status">
              <div style="min-width: 120px">
                 <v-label class="subtitle-card-order mb-0">Entregados</v-label>

              </div>
              <div style="max-width: 150px;" class="w-100 mt-2 mr-2">

                <v-progress-linear :model-value="calculateShippingsByState(ShippingState.STATE_DELIVERED, order.seller_order.shippings) * 100 / order.seller_order.shippings.length" color="green" height="12"></v-progress-linear>
              </div>
               <v-label class="subtitle-card-order mb-0">{{ calculateShippingsByState(ShippingState.STATE_DELIVERED, order.seller_order.shippings) }}</v-label>
            </div>
            <div class="shipping-status">
              <div style="min-width: 120px">
                 <v-label class="subtitle-card-order mb-0">En camino</v-label>

              </div>
              <div style="max-width: 150px;" class="w-100 mt-2 mr-2">

                <v-progress-linear :model-value="calculateShippingsByState(ShippingState.STATE_ON_THE_WAY, order.seller_order.shippings)  * 100 / order.seller_order.shippings.length" color="blue" height="12"></v-progress-linear>
              </div>
               <v-label class="subtitle-card-order mb-0">{{  calculateShippingsByState(ShippingState.STATE_ON_THE_WAY, order.seller_order.shippings) }}</v-label>
            </div>
            <div class="shipping-status">
              <div style="min-width: 120px">
                 <v-label class="subtitle-card-order mb-0">No entregados</v-label>

              </div>
              <div style="max-width: 150px;" class="w-100 mt-2 mr-2">

                <v-progress-linear :model-value="calculateShippingsByState(ShippingState.STATE_NOT_DELIVERED, order.seller_order.shippings)  * 100 / order.seller_order.shippings.length" color="yellow" height="12"></v-progress-linear>
              </div>
               <v-label class="subtitle-card-order mb-0">{{  calculateShippingsByState(ShippingState.STATE_NOT_DELIVERED, order.seller_order.shippings) }}</v-label>
            </div>
          </div>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ShippingState from "@/constants/shipping_state";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: "",
    },
    customClassText: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default: () => []
    }
  },
  data() { 
    return {
      ShippingState:ShippingState
    }
  },
  methods: {
    // Método genérico para filtrar los shippings por estado
    calculateShippingsByState(state_id, shippings) {    
      return  shippings.filter(ship => ship.state.id === state_id).length; 
    },
    viewItemPage(item) {
      this.$router.push(`/orders-quoting/${item.code}`);
    },
  }
};
</script>