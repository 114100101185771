<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" max-width="1100px" transition="dialog-bottom-transition">

      <v-card style="height: 100%;">
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="updateItem">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">Mi Perfil</span>
          </v-card-title>
          <v-container fluid>
            <v-row>
              
              <v-col cols="4" md="4" sm="12">
                <div class="container-avatar d-flex text-start align-center">
                  <v-avatar color="surface-variant" size="80">
                    <input type="file" ref="fileInput" style="display: none" @change="previewImage">
                    <v-icon v-if="item.profile_picture_url == null && imageUrl == null"
                      @click="openFileInput">mdi-camera</v-icon>
                    <v-img v-if="item.profile_picture_url != null && imageUrl == null" :src="item.profile_picture_url"
                      @click="openFileInput" alt="Avatar Image" />
                    <v-img v-if="imageUrl != null" :src="imageUrl" @click="openFileInput" alt="Avatar Image" />

                  </v-avatar>
                  
                  <div class="ms-2">
                    <div>
                      <v-label class="text-avatar text-white mb-0">{{ item.business_name }}</v-label>
                    </div>
                    <div>
                       <v-label class="subtext-avatar text-white mb-0">{{ item.email }}</v-label>
                     </div> 
                  </div>
                </div>
                <v-card class="mx-auto" tile style="height: 100%; max-height: 533px;">
                  <v-list class="pt-0">
                    <v-list-item-group v-model="selectedItem" color="primary" mandatory>
                      <v-list-item v-for="(item, i) in items" :key="i" @click="item.func" :active="selectedItem === i"
                        active-class="list-item-active">
                        <v-list-item-content class="d-flex">
                          <font-awesome-icon class="me-3 mt-1" :icon="item.icon" />
                          <v-list-item-title :class="{ 'font-weight-bold': selectedItem === i }"
                            v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>

                </v-card>
              </v-col>
              <v-col cols="8" md="8" sm="12">
                <v-card class="mx-auto">
                  <div v-if="selectedItem == 0">
                    <v-card-text>
                      <v-label>Nombre y apellido</v-label>
                      <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                        v-model="item.name"></v-text-field>
                      <v-label>Email</v-label>
                      <v-text-field density="compact" :rules="[rules.required, rules.email]" persistent-hint
                        variant="outlined" v-model="item.email" autocomplete="off"></v-text-field>
                      <v-label>Teléfono</v-label>
                      <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                        v-model="item.phone"></v-text-field>
                      <v-label>Razón Social</v-label>
                      <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                        v-model="item.business_name" required></v-text-field>

                      <v-label>CUIT</v-label>
                      <v-text-field density="compact" :rules="[rules.required, rules.cuit]" persistent-hint
                        variant="outlined" v-model="item.cuit"></v-text-field>

                    </v-card-text>

                  </div>

                  <div v-if="selectedItem == 1">
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <v-label>Provincia</v-label>
                          <v-combobox density="compact" :rules="[rules.requiredComboBox]" persistent-hint
                            item-title="name" item-value="id" v-model="item.provincia" :items="provincias"
                            variant="outlined" autocomplete="off"
                            @update:modelValue="loadPostalCodes(item.provincia.id)"></v-combobox>

                        </v-col>
                        <v-col cols="6">
                          <v-label>Código postal</v-label>
                          <v-combobox v-model="item.postal_code" density="compact" variant="outlined" item-title="code"
                            item-value="id" :items="postalCodes" autocomplete="off" :disabled="disabled"
                            :rules="[rules.requiredComboBox]" clearable :loading="loadingPostalCode"
                            @update:modelValue="loadPregeo()"></v-combobox>
                        </v-col>
                      </v-row>
                      <v-label>Localidad</v-label>
                      <v-text-field v-model="item.city" :disabled="disabled" density="compact"
                        :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>

                      <v-label>Dirección</v-label>
                      <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                        v-model="item.address" :error-messages="errorAddress ? [errorAddress] : []" @change="loadPregeo"
                        :loading="loadingAddress"></v-text-field>
                      <v-label>Referencia/Piso/Departamento</v-label>
                      <v-text-field v-model="item.origin_reference" :disabled="disabled" density="compact"
                        persistent-hint variant="outlined"></v-text-field>
                      <v-row>
                        <v-col cols="6">
                          <v-label>Latitud</v-label>
                          <v-text-field v-model="item.lat" :disabled="disabled" density="compact"
                            :rules="[rules.required, rules.float, rules.latitud]" persistent-hint
                            variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-label>Longitud</v-label>
                          <v-text-field v-model="item.lng" :disabled="disabled" density="compact"
                            :rules="[rules.required, rules.float, rules.longitud]" persistent-hint
                            variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                          <Maps :lat="item.lat" :lng="item.lng"></Maps>
                        </v-col>

                      </v-row>

                    </v-card-text>
                  </div>
                  <v-card-actions>

                    <v-spacer></v-spacer>
                    <Button flat type="button" class="btn text-capitalize text-white me-1 btn-modal" rounded="xl"
                      height="50" text @click="$emit('updateDialog', false)" label="Cerrar" color="error"></Button>

                    <Button flat :loading="loading" text type="submit"
                      class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                      label="Editar" v-if="!disabled"></Button>
                  </v-card-actions>

                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Maps from "@/components/Maps.vue";
import Button from "@/components/Button.vue";
import { profileService } from "@/libs/ws/Public/profileService";
import { provincesService } from "@/libs/ws/Public/provincesService";
import { postalCodesService } from "@/libs/ws/Public/postalCodesService";
import { shippingService } from "@/libs/ws/Seller/shippingService";

export default {
  mixins: [ValidMixin],
  components: { Button, Maps },
  props: {
    service: Object,
    dialog: Boolean,
    updateDialog: Function,
    startItem: Number,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      loadingPostalCode: false,
      loadingAddress: false,
      errorAddress: false,
      name: "",
      selectedItem: this.startItem,
      items: [
        {
          icon: "user",
          text: "Mis Datos",
          func: this.handleStepMisDatos
        },
        {
          icon: "map-marker",
          text: "Punto de Recoleccion",
          func: this.handleStepRecollecion
        }
      ],
      item: {
        email: "",
        name: "",
        phone: "",
        address: "",
        city: "",
        cuit: "",
        business_name: "",
        provincia: "",
        postal_code: "",
        lat: null,
        lng: null
      },
      provincias: [],
      postalCodes: [],
      imageUrl: null,
      imageFile: null

    };
  },

  watch: {
    dialog(newVal) {
      if(newVal) {
        this.selectedItem = this.startItem
      }
    }
  },
  mounted() {
    this.loadProvincias()
    this.loadProfile()
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.imageFile = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
        this.updateImageProfile();
      };
      reader.readAsDataURL(file);
    },
    updateImageProfile() {
      if (!this.imageFile) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result; // Enviar el base64 completo

        const payload = {
          profile_picture_file: base64String,
        };

        this.loading = true;

        profileService
          .updatePhoto(payload)
          .then((response) => {
            if (response.status) {
              this.toast.success("La imagen se actualizó correctamente.");
              this.imageUrl = response.result.url;
              this.$emit("updateDialog", false);
              this.loadProfile();
            } else {
              this.toast.error(response.message || response.msg);
            }
          })
          .catch((error) => {
            this.toast.error(error.msg || "Error al actualizar la imagen.");
          })
          .finally(() => {
            this.loading = false;
          });
      };

      reader.readAsDataURL(this.imageFile);
    },
    handleStepMisDatos() {
      this.selectedItem = 0;
    },
    handleStepRecollecion() {
      this.selectedItem = 1;
    },
    loadPregeo() {

      if (this.item.address) {
        this.loadingAddress = true;
        this.errorAddress = null;

        const address = {
          address: this.item.address + ', ' + this.item.city + ', ' + this.item.postal_code.code + ', ' + this.item.provincia.name
        }

        shippingService
          .pregeo(address)
          .then((response) => {
            this.item.lat = response.result.lat;
            this.item.lng = response.result.lng;
            if (response.status === false) {
              this.errorAddress = response.msg;
            }
          })
          .catch((response) => {
            this.toast.error(response.msg)
          })
          .finally(() => {
            this.loadingAddress = false;
          });
      }
    },
    loadProvincias() {
      provincesService
        .getAllProvince()
        .then((response) => {
          this.provincias = response.result.list;
        })
        .catch((e) => {
          console.log("service.load provinces profile seller error");
          console.log(e);
        });
    },
    loadPostalCodes(id_provincia, postalCodeNull = true) {
      if (postalCodeNull) {
        this.item.postal_code = null;
      }
      this.loadingPostalCode = true;

      const body = {
        provincia: {
          id: id_provincia
        }
      }

      postalCodesService
        .getPostalCodes(body)
        .then((response) => {
          this.postalCodes = response.result.list;
        })
        .catch((e) => {
          console.log("service postal codes error");
        })
        .finally(() => {
          this.loadingPostalCode = false;
        });

    },
    loadProfile() {
      profileService
        .filter()
        .then((response) => {

          if (response.result.provincia != null) {
            this.loadPostalCodes(response.result.provincia.id, false)
          }

          this.item.email = response.result.user.email;
          this.item.name = response.result.name;
          this.item.phone = response.result.phone;
          this.item.address = response.result.address;
          this.item.postal_code = response.result.postal_code || "";  //{ id: response.result.postal_code.id, code: response.result.postal_code.code };
          this.item.city = response.result.city;
          this.item.cuit = response.result.cuit;
          this.item.business_name = response.result.business_name;
          this.item.provincia = response.result.provincia != null ? { id: response.result.provincia.id, name: response.result.provincia.name } : "";
          this.item.lng = response.result.lng;
          this.item.lat = response.result.lat;

        })
        .catch((e) => {
          console.log("service.load profile seller error ");
          console.log(e);
        })
    },
    updateItem() {
      if (this.loading) return;


      if (this.valid) {
        this.loading = true;

        var data = this.item;


        this.service
          .update(data)
          .then((response) => {
            if (response.status) {
              this.toast.success("El perfil se modificó correctamente.");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((response) => {
            this.toast.error(response.msg);
          })
          .finally(() => {
            this.loading = false;
          });


      }


    },
  },
};
</script>
